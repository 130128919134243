import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import {
  DEVICE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants";

export const SideNavbar = styled.div`
  width: 260px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  margin-left: 1rem;
  padding: 1.5rem;
  border-radius: 1rem;
  @media ${DEVICE.tablet} {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    
  }
`;

export const MobileSideNavbar = styled.div`
  display: none;
  background-color: #ebf7fb;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  padding: 1rem;
  @media ${DEVICE.tablet} {
    display: ${(props) => (props?.showNav ? "flex" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    flex-direction: column;
    z-index:1 !important;
  }
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: 47px;
  line-height: 47px;
  color: #0099cc;
  text-decoration: none;
  padding-left: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  &.active {
    background-color: #f5fbfc;
    border-radius: 8px 0px 0px 8px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #7aba56;
  margin-bottom: 40px;
  padding-top: 40px;
  justify-content: space-between;
`;

export const TopHeaderBackContainer = styled.div`
  cursor: pointer;
`;
export const TopHeaderCloseContainer = styled.div`
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;

export const ArrowBackImage = styled.img`
  margin-right: 10px;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: #000000;
`;
export const ProfileIcon = styled.img`
  margin-right: 12px;
`;
export const PhoneNumber = styled.div``;
export const SidebarImage = styled.img`
  margin-right: 10px;
`;
export const PatientName = styled.div`
  color: #0099cc;
  font-size: 20px;
`;
export const ProfileInfoContainer = styled.div`
  width: 200px;
  margin: 0 auto;

  align-items: center;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const MobileLogoutButton = styled.button`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-self: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
`;

export const PatientEmail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  word-break: break-all;
`;