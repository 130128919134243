import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ICalendar,
  IGenderIcon,
  IGreenBackArrow,
  IProfileImageSmall,
} from "../../../assets";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  ArrowBackImage,
  PatientName,
  PhoneNumber,
  ProfileIcon,
  ProfileInfo,
  ProfileInfoContainer,
  ProfileWrapper,
  SideNavbar,
  TopHeader,
  PatientEmail,
} from "./style.components";

const SideNav = () => {
  const navigate = useNavigate();
  const { patients, selectedPatientId } = useSelector(patientSelector);
  const patientRecord = patients?.filter(
    (patient) => patient?.patient_id === selectedPatientId
  );
  const phone_number = patientRecord?.[0]?.phone_number;
  const patient_name = patientRecord?.[0]?.patient_name;
  const gender = patientRecord?.[0]?.gender;
  const dob = patientRecord?.[0]?.dob;
  const email = patientRecord?.[0]?.email;

  return (
    <SideNavbar>
      <TopHeader onClick={() => navigate("/home")}>
        <div>
          <ArrowBackImage src={IGreenBackArrow} alt="back-arrow" />
          Home
        </div>
      </TopHeader>
      <ProfileWrapper>
        <ProfileIcon src={IProfileImageSmall} alt="Profile Icon" />
      </ProfileWrapper>

      <ProfileInfoContainer>
      <PatientName>{patient_name}</PatientName>
      <PatientEmail title={phone_number}>{phone_number}</PatientEmail>
      <PatientEmail title={email}>{email}</PatientEmail>
        {gender && (
          <ProfileInfo>
            <ArrowBackImage src={IGenderIcon} alt="gender" />
            {gender
              ? `${gender?.charAt(0)?.toUpperCase()}${gender?.slice(1)}`
              : "-"}
          </ProfileInfo>
        )}
        {dob && (
          <ProfileInfo>
            <ArrowBackImage src={ICalendar} alt="calendar" />
            {dob ? moment(dob, "DD-MM-YYYY").format("DD-MMM-YYYY") : "-"}
          </ProfileInfo>
        )}
      </ProfileInfoContainer>
    </SideNavbar>
  );
};

export default SideNav;
