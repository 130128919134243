import { createSlice } from "@reduxjs/toolkit";
import { getRecordTypeAction } from "../actions";

const initialState = {
  isLoading: null,
  recordType: null,
};

const recordTypeSlice = createSlice({
  name: "record-type",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get record type
    builder.addCase(getRecordTypeAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRecordTypeAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.recordType = payload?.result;
    });
    builder.addCase(getRecordTypeAction.rejected, (state) => {
      state.isLoading = false;
      state.recordType = null;
    });
  },
});

export const recordTypeSelector = (state) => state.recordType.recordType;
export default recordTypeSlice.reducer;
