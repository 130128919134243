import { Outlet } from "react-router-dom";
import { Footer, Header, SideNav } from "../../components/shared";
import { MainBody, ParentContainer } from "./style.components";

const PatientRoot = () => {
  return (
    <>
      <Header />
      <ParentContainer>
        <SideNav />
        <MainBody>
          <Outlet />
        </MainBody>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default PatientRoot;
