import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MetaInfo from "../MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import "./InfoSection.css";
import { getStudyInfo, getScansDetails } from "../../store/actions/records.action";
import { removeSpecialCharacters } from "../../utilities/common";
import styled from "styled-components";
import Breadcrumb from "../../components/Breadcrumb";
import { ShimmerContentBlock } from "react-shimmer-effects";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const InfoSection = () => {
  const [dicomInfo, setDicomInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [scans, setScans] = useState([]);
  const { studyFhirId } = location.state || {};
  const navigate = useNavigate();

  const fetchStudyDetails = async () => {
    setLoading(true);
    try {
      const [action, scansAction] = await Promise.all([
        dispatch(getStudyInfo({ fhir_id: studyFhirId })),
        dispatch(getScansDetails({ fhir_id: studyFhirId })),
      ]);
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.data || [];
        setDicomInfo(responseData);
        const responseScansData = scansAction?.payload?.data || [];
        setScans(responseScansData);
      } else {
        console.error("Failed to fetch study info:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch study info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyDetails();
  }, []);

  const handleBackNavigation = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];
  return (
      <Box m={5} mt={0}>
        <Breadcrumb links={breadCrubLinks} />
        <TopHeader>Studies</TopHeader>
        {!loading && dicomInfo ? (
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="stretch">
          <Grid item>
            <Paper
              className="box-wrapper"
              sx={{
                background: "#EBF7FB",
                borderRadius: {
                  xs: "1rem 1rem 0 0",
                  md: "1rem 0 0 1rem",
                },
                paddingBottom: 2,
                display: 'flex',
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                p={2}
                className="info-header"
                sx={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: { xs: "1rem", sm: 0 },
                }}
              >
                <Typography variant="h6" sx={{ color: "#FFF" }}>
                  {removeSpecialCharacters(dicomInfo?.study_info?.patient_name)}
                </Typography>
              </Box>
              <Box>
                <FileInfoSection recordInfo={dicomInfo?.study_info} />
                <Box
                  display="flex"
                  m={2}
                  p={2}
                  flexDirection="column"
                  sx={{ background: "#FFF", borderRadius: 4 }}
                >
                  <Box className="series-text" pl={2}>
                    Series {scans.length ? `(${scans.length})` : ""}
                  </Box>
                  <Box display="flex">
                    <DisplaySeries recordInfo={scans} />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <MetaInfo recordInfo={dicomInfo} scans={scans} sx={{ width: "100%" }} />
          </Grid>
      </Box>
      
        ) : (
          <Box m={2}>
            <ShimmerContentBlock
              title
              text
              cta
              thumbnailWidth={100}
              thumbnailHeight={100}
            />
          </Box>
        )}
      </Box>
  );
};

export default InfoSection;
