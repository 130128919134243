import styled from "styled-components";
import { FONT_FAMILY_GENERAL } from "../../utilities/constants";

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const TitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000;
`;

export const SubTitleText = styled.div`
  font-size: 14px;
  color: #808080;
  margin-top: 10px;
`;

export const CountryPicker = styled.div`
  background-color: #fff;
  height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content:start;
  margin-top: 20px;
  padding: 10px;
`;

export const CountryListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
  &:last-child {
    border-bottom: none;
  }
`;
export const LeftSection = styled.div`
  display: flex;
   flex: 1;
  justify-content: flex-start;
  align-items: center;
`;
export const RightSection = styled.div`
  display: flex;
  flex: 0.65;
  justify-content: flex-start;
  align-items: center;
`;
export const CountryImage = styled.img`
  width: 24px;
  height: 24px;
`;
export const CountryName = styled.div`
  font-size: 15px;
  color: #000;
  margin-left: 10px;
`;

export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;