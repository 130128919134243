import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateOTP, verifyOTP } from "../../api";
import { getAccessToken, getSupportedCountries, signout } from "../../api/auth.api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const generateOTPAction = createAsyncThunk(
  "generate-otp",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Sending you OTP...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await generateOTP(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "OTP sent successfully",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while generating OTP",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while generating OTP");
    }
  }
);

export const verifyOTPAction = createAsyncThunk(
  "verify-otp",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Validating OTP...",
          severity: "info",
          autoHide: "no",
        })
      );
        const response = await verifyOTP(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "OTP verified successfully",
          severity: "success",
        })
      );
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while verifying OTP",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while verifying OTP");
    }
  }
);


export const getVerifyAccessToken = createAsyncThunk(
  "access-token",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Getting Access Token...",
          severity: "info",
          autoHide: "no",
        })
      );
      
      const accessTokenResponse = await getAccessToken(data)

      thunkAPI.dispatch(
        setShowStatus({
          message: "User Logged In.....",
          severity: "success",
        })
      );
      thunkAPI.dispatch(unsetShowStatus());
      return accessTokenResponse;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error");
    }
  }
);


export const signoutAction = createAsyncThunk(
  "signout",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Logging you out...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await signout();
      thunkAPI.dispatch(
        setShowStatus({
          message: "Logged out",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while logging out",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while logging out");
    }
  }
);

export const getSupportedCountriesAction = createAsyncThunk(
  "get/countries",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching countries...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getSupportedCountries();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching countries",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching countries"
      );
    }
  }
);
