import { Box, Modal, Select, MenuItem, InputAdornment } from "@mui/material";
import React, {useState} from "react";
import {
  ButtonWrapper,
  SaveButton,
  CloseButton,
  CloseButtonWrapper,
  StyledTextField,
  StyledLabel,
  ModalHeader,
  ModalBody,
  StyledFormControl,
} from "./style.components";
import { ICloseModal } from "../../../assets";
import { API_ENDPOINT } from "../../../utilities/constants";
import { apiClient } from "../../../api/api-client";
import useIsAuthenticated from "../../../hooks/useIsAuthenticated";
import { Loader } from "../../shared";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  width: '82%',
  bgcolor: "background.paper",
  boxShadow: 24,
  pl: 4,
  pb: 4,
  outline: 'none'
};

const AccessCodeModal = ({ open, handleClose,fetchAllPatients,handlePatientClick }) => {
  const isAuthenticated = useIsAuthenticated();
 
  const [accessCode, setAccessCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState('');

  const veryfyAccesCode = async (codeFromUrl) => {
    setIsVerifying(true);
    setError('')

    try {
      const url = `${API_ENDPOINT.VERFYCONSENT}`;
      const response = await apiClient.post(url, {
        access_code: accessCode || codeFromUrl,
      });
      console.log("respons", response.status);
      if (response.status === "success") {
        if (isAuthenticated) fetchAllPatients();

        setAccessCode("");
        setIsVerifying(false);
       handlePatientClick(response.result.patient_id)
      setError('')
      handleClose(false)

      }
    } catch (error) {
      console.log(error);
      setError(error|| 'somthing went wrong')
      setIsVerifying(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseButtonWrapper>
          <CloseButton src={ICloseModal} onClick={handleClose} />
        </CloseButtonWrapper>
        <ModalHeader id="modal-modal-title" variant="h6" component="h2">
        Access Code
        </ModalHeader>
        <ModalBody>
         
          {/* <StyledLabel></StyledLabel> */}
          <Box display="flex" justifyContent="space-between">
            <StyledTextField 
            style={{ width: "80%", marginRight: "2%" }}
              variant="outlined" size="small" 
               onChange={(e) => setAccessCode(e.target.value)}
                        type={"text"}
                        value={accessCode}
                        placeholder="Enter access code"
               
               />

          </Box>
          {accessCode && error ?<p style={{color:'red',fontSize:'12px'}}>{error && error}</p>:''}
         
          <ButtonWrapper>
            <SaveButton onClick={()=>veryfyAccesCode()}
             disabled={!accessCode || isVerifying}
            >
            {isVerifying ? (
                          <Loader height={30} size={30} />
                        ) : (
                          "Submit"
                        )}
              
              </SaveButton>
          </ButtonWrapper>

          <StyledLabel  style={{fontSize:'12px',marginTop:'10px'}}>
        Note : Once you put the code, access will be provided for 7 days. You can reuse the code again after 7 days to view the file.

        </StyledLabel>
        </ModalBody>
      </Box>
    </Modal>
  );
};
export default AccessCodeModal;
