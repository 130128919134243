import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_VARIANT,
  DEVICE,
  FONT_FAMILY_GENERAL,
} from "../../utilities/constants";

export const LoginContainer = styled.div({
  backgroundColor: "#0099CC",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
  padding:'10px'
});

export const LoginLogo = styled.div`
  margin-bottom: 60px;
  text-align: center;
  @media ${DEVICE.laptopL} {
    margin-bottom: 30px;
    img {
      width: 80%;
    }
  }
  @media ${DEVICE.laptop} {
    margin-bottom: 0px;
    img {
      width: 60%;
    }
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  @media ${DEVICE.laptopL} {
    justify-content: center;
    width: 90%;
  }
`;

export const LoginFormRight = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 5px;
  height: 300px;
  margin-left: 30px;
  width: 280px;

  @media ${DEVICE.laptopL} {
    margin-top: 1rem;
    margin-left: 0;
  }
  @media (max-width: 899px) {
    width: 280px;
    height: 240px;
    padding: 25px;
  }
  @media ${DEVICE.mobileM} {
    width: 220px;
    padding: 20px;
  }
  @media ${DEVICE.mobileS} {
    width: 200px;
  }
`;

export const FormMessage = styled.div({
  color: "#0099CC",
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
  fontSize: "28px",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const FormFieldSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: 899px) {
    margin-bottom: 20px;
  }
`;

export const FormLabel = styled.div({
  color: "#808080",
  fontSize: "16px",
  textAlign: "left",
  marginBottom: "10px",
});

export const FormFieldRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const CustomSelect = styled.select({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flex: 1,
  width: "100%",
  maxWidth: "80px",
  minWidth: "70px",

  paddingLeft: "10px",
  marginRight: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

export const CustomInput = styled.input({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flexGrow: 1,
  width: "100%",
  minWidth: "20px",
    [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
  paddingLeft: "10px",
});

export const CustomButton = styled.input({
  backgroundColor: COLOR_VARIANT,
  border: "none",
  textDecoration: "none",
  height: "53px",
  width: "100%",
  cursor: "pointer",
  color: "#FFF",
  borderRadius: "8px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: '#cccccc',
    color: "#666666",
    cursor: "not-allowed"
  }
});

export const ActionText = styled.div({
  color: "#7ABA56",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  paddingLeft: "10px",
  ":hover": {
    textDecoration: "underline",
  },
});

export const TCText = styled.div({
  textAlign: "left",
  fontSize: "13px",
  color: "#808080",
  marginBottom: "20px",
});

export const TCLink = styled.a({
  textDecoration: "none",
  fontSize: "13px",
  color: COLOR_PRIMARY,
  marginLeft: "5px",
});

export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  @media (max-width: 899px) {
    margin-bottom: 10px;
  }
`;
export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;
export const BlackRightArrowImg = styled.img``;
export const BackText = styled.div`
  color: #000000;
  font-size: 16px;
`;

export const LoginByText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
  font-weight: 600;
  @media (max-width: 899px) {
    margin-bottom: 15px;
  }
`;

export const MobileIconImg = styled.img``;

export const EmailIconImg = styled.img``;
export const InfoContainer = styled("div")({
  backgroundColor: "#EBF7FB",
  border: "1px solid #DBE9EE",
  borderRadius: "8px",
  padding: "14px",
  paddingRight: 0,
  margin: "14px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
});

export const IconTextContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontWeight: 600,
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "14px",
  },
});