import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getSupportedCountriesAction } from "../../store/actions";
import { authActions, authSelector } from "../../store/slice/auth.slice";
import IBackArrow from "../../assets/arrow-right-black.svg";

import {
  Container,
  CountryImage,
  CountryListItem,
  CountryName,
  CountryPicker,
  LeftSection,
  RightSection,
  SubTitleText,
  TitleText,
  BackArrowImg
} from "./style.component";

const ComplianceRoot = ({handleContrySlection,supportedCountriesData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [supportedCountries,setSupportedCountries ] = useState([]);

  useEffect(() => {
    // await dispatch(getSupportedCountriesAction());
    if(supportedCountriesData?.length){
      setSupportedCountries(supportedCountriesData)
    }
  }, [supportedCountriesData]);
   



  const onCountryListItemClick = async (data) => {

    await dispatch(authActions.selectedCountry(data));
         handleContrySlection(data)
    let accessCode = searchParams.get('accessCode')
    navigate(`/login${accessCode?'?accessCode='+accessCode:''}`);
  };

  const getCountryList = () => {
    if (supportedCountries?.length <= 0) return;
    return supportedCountries?.map((item, index) => {
      return (
        <CountryListItem
          key={`country_list_${index}`}
          onClick={() => onCountryListItemClick(item)}
        >
          <LeftSection>
            <CountryImage src={item?.icon} alt={item?.display_name} />
            <CountryName>{item?.display_name}</CountryName>

          </LeftSection>
          <div>
          <BackArrowImg style={{height:'20px',width:'20px'}} src={IBackArrow} />
          </div>
        </CountryListItem>
      );
    });
  };


  return (
    <Container>
      <CountryPicker>{getCountryList()}</CountryPicker>
    </Container>
  );
};

export default ComplianceRoot;
