import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Popover, Typography, Avatar } from "@mui/material";
import { ILogout, ILogoutDownArrow } from "../../../../assets";

import useIsAuthenticated from "../../../../hooks/useIsAuthenticated";
import { signoutAction } from "../../../../store/actions";
import { authSelector } from "../../../../store/slice/auth.slice";
import { COLOR_VARIANT } from "../../../../utilities/constants";
import { UserDetailsMobile } from "./style.components";

const ProfileDropdownMobile = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowLogout, setShowLogout] = useState(false);

  const { userDetails, selectedCountry } = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    handleClose();
    await dispatch(signoutAction());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowLogout(!isShowLogout);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowLogout(!isShowLogout);
  };

  return (
    <>
      <UserDetailsMobile onClick={handleClick}>
        <img
          src={selectedCountry?.icon}
          alt="selected_country"
          style={{ height: 20, marginRight: 10 }}
        />
        <Avatar alt={"Avatar"} sx={{ width: 25, height: 25 }} />
          <img
            src={ILogoutDownArrow}
            height={5}
            alt="down-arrow"
            style={{ marginLeft: "8px" }}
          />
        <Popover
          open={isShowLogout}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{ color: "#808080", fontSize: "16px", borderRadius: "8px", marginTop:"10px" }}
        >
           <Box sx={{ padding: "10px 10px 0 40px"}}>
              <Typography sx={{  fontSize: "14px"  }}>{userDetails?.first_name}</Typography>
              <Typography sx={{ color: "#808080", fontSize: "12px", marginLeft: "3px"}}>{userDetails?.phone_number}</Typography>
            </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: `1px solid ${COLOR_VARIANT}`,
              padding: "0 15px",
              cursor: "pointer",
            }}
            onClick={handleLogout}
          >
            <img width={20} src={ILogout} alt="Logout Icon" />
            <Typography sx={{ p: 1, color: "#808080", fontSize: "16px" }}>
              Logout / Change Country
            </Typography>
          </Box>
        </Popover>
      </UserDetailsMobile>
    </>
  );
};

export default ProfileDropdownMobile;
