import { useState } from "react";
import { IHamburgerIcon, ILogoWhite  } from "../../../assets";

import MobileSideNav from "../side-nav/mobile-side-nav";
import ProfileDropdown from "./profile-dropdown";
import ProfileDropdownMobile from "./profile-dropdown/profile-dropdown-mobile";
import { useLocation } from "react-router-dom";
import { LogoImage, LogoImageCont, StickyHeader, HamburgerIconCont } from "./style.components";

const FixedHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const location = useLocation();
  const isHome = location.pathname === "/" || location.pathname === "/home";

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={ILogoWhite} />
        </LogoImageCont>
        <ProfileDropdown />
        <ProfileDropdownMobile />
        {!isHome && <HamburgerIconCont onClick={toggleSidebar}>
        <img src={IHamburgerIcon} alt="Menu" />
      </HamburgerIconCont>}
      </StickyHeader>
      <MobileSideNav
        showMobileNav={isSidebarOpen}
        setShowMobileSideNav={setIsSidebarOpen}
      />
    </>
  );
};

export default FixedHeader;
