import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RootRouter from "../navigation";
import { persistor, store } from "../store/store";

const THEME = createTheme({
  typography: {
    fontFamily: `"Noto Sans Display", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#7ABA57",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <RootRouter />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
