import styled from "styled-components";
import { TextField, Typography, FormControl } from "@mui/material";

export const StyledTextField = styled(TextField)`
  && {
    background-color: #EBF7FB;
    border-radius: 8px;
    width: 100%;
    border: none;
    font-size: 16px;
    .MuiOutlinedInput-input {
      border: none;
      font-size: 16px;
    }
    .MuiOutlinedInput-root {
      fieldset,
      &.Mui-focused fieldset {
        border: none;
      }
    }
  }
`;

export const StyledLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 18px !important;
  margin-bottom: 40px !important;
`;

export const ModalBody = styled.div`
  padding-right: 32px;
`;

export const StyledFormControl = styled(FormControl)`
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset, fieldset {
      border: none !important;
    }
  }

  .MuiInput-underline:after, .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input {
    border-radius: 8px;
    background-color: #EBF7FB;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
`;
export const CloseButton = styled.img`
  cursor: pointer;
`;
export const CloseButtonWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  background: transparent;
`;
export const SaveButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  &:disabled {
    background-color: #CCCCCC;
    color: #666666;
    cursor: not-allowed;
}
`;
