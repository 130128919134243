import { createSlice } from "@reduxjs/toolkit";
import { getThumbnails } from "../actions";

const initialState = {
  isLoading: null,
  thumbnails: null,
};

const thumbnailsSlice = createSlice({
  name: "thumbnail",
  initialState,
  reducers: {
    resetThumbnail: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getThumbnails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getThumbnails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if(payload?.data) {
        const { data } = payload;
        state.thumbnails = {
          ...state.thumbnails,
          ...data,
        };
      }
    });
    builder.addCase(getThumbnails.rejected, (state) => {
      state.isLoading = false;
      state.records = null;
    });
  },
});

export const thumbnailSelector = (state) => state.thumbnail?.thumbnails || {};
export default thumbnailsSlice.reducer;
export const { resetThumbnail } = thumbnailsSlice.actions;
