import moment from "moment";

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return "";
  return moment(timestamp).local().format("DD-MMM-YYYY, hh:mm a");
};

export const removeSpecialCharacters = (str) => {
  if(!str) return '';
  return str.replace(/[^a-zA-Z0-9. ]/g, '');
}