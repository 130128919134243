import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ICalendar,
  ICloseModal,
  IGenderIcon,
  IGreenBackArrow,
  IProfileImageSmall,
} from "../../../assets";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  ArrowBackImage,
  MobileSideNavbar,
  PatientName,
  PhoneNumber,
  ProfileIcon,
  ProfileInfo,
  ProfileInfoContainer,
  ProfileWrapper,
  TopHeader,
  TopHeaderBackContainer,
  TopHeaderCloseContainer,
} from "./style.components";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MobileSideNav = ({ showMobileNav, setShowMobileSideNav }) => {
  const navigate = useNavigate();
  const { patients, selectedPatientId } = useSelector(patientSelector);
  const patientRecord = patients?.filter(
    (patient) => patient?.patient_id === selectedPatientId
  );
  const phone_number = patientRecord?.[0]?.phone_number;
  const patient_name = patientRecord?.[0]?.patient_name;
  const gender = patientRecord?.[0]?.gender;
  const dob = patientRecord?.[0]?.dob;
  const email = patientRecord?.[0]?.email;

  const location = useLocation();
  const PATH_TO_IGNORE = ["/home", "/"];

  return (
    <MobileSideNavbar showNav={showMobileNav}>
      <TopHeader>
        {!PATH_TO_IGNORE.includes(location.pathname) ? (
          <TopHeaderBackContainer onClick={() => navigate("/home")}>
            <ArrowBackImage src={IGreenBackArrow} alt="back-arrow" />
              Home
          </TopHeaderBackContainer>
        ) : (
          <TopHeaderBackContainer />
        )}

        <IconButton onClick={() => setShowMobileSideNav(false)}>
          <CloseIcon />
        </IconButton>
      </TopHeader>
      {!PATH_TO_IGNORE.includes(location.pathname) ? (
        <>
          <ProfileWrapper>
            <ProfileIcon src={IProfileImageSmall} alt="Profile Icon" />
          </ProfileWrapper>
          <PatientName>{patient_name}</PatientName>
          <PhoneNumber>{phone_number}</PhoneNumber>
          <PhoneNumber>{email}</PhoneNumber>
          <ProfileInfoContainer>
            {gender && (
              <ProfileInfo>
                <ArrowBackImage src={IGenderIcon} alt="gender-icon" />
                {`${gender?.charAt(0)?.toUpperCase()}${gender?.slice(1)}`}
              </ProfileInfo>
            )}
            {dob && (
              <ProfileInfo>
                <ArrowBackImage src={ICalendar} alt="calendar-icon" />
                {moment(dob, "DD-MM-YYYY").format("DD-MMM-YYYY")}
              </ProfileInfo>
            )}
          </ProfileInfoContainer>
        </>
      ) : null}
    </MobileSideNavbar>
  );
};

export default MobileSideNav;
