import styled from "styled-components";
import { DEVICE } from "../../../../utilities/constants";

export const UserDetails = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const UserDetailsMobile = styled.div`
  display: none;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  @media ${DEVICE.tablet} {
    display: flex;
  }
`;
