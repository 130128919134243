import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import {
  IBlueArrow,
  IDobIcon,
  IEmailIcon,
  IGenderIcon,
  IProfileImageSmall,
} from "../../assets";
import { Loader, SnackBar } from "../../components/shared";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { getPatientsAction } from "../../store/actions/patient.action";
import {
  patientSelector,
  setSelectedPatient,
} from "../../store/slice/patient.slice";
import {
  ArrowImage,
  Container,
  DetailItem,
  DetailSection,
  DobDate,
  DobImage,
  DobText,
  DobYears,
  Email,
  EmailIcon,
  EmailSection,
  EmptyListMessage,
  GenderImage,
  GenderText,
  Header,
  HeaderLeft,
  HeaderRight,
  HeaderWrapper,
  Name,
  PatientCard,
  PatientContainer,
  PatientInfo,
  PhoneNumber,
  ProfileImage,
  ProfileSection,
  SubmitButton,
  AccessCodeBtn,
} from "./style.components";
import { CustomButton, CustomInput } from "../login/style.components";
import { Input, TextField } from "@mui/material";
import { API_ENDPOINT } from "../../utilities/constants";
import { apiClient } from "../../api/api-client";
import AccessCodeModal from "../../components/layout/access_code_modal";

const capitalizeFirstLetter = (string) => {
  if (!string || typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const createData = (
  patient_id,
  patient_name,
  gender,
  phone_number,
  dob,
  email
) => ({
  patient_id,
  patient_name,
  gender,
  phone_number,
  dob,
  email,
});

const PatientList = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { patients, searchedPatient, total } = useSelector(patientSelector);
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [accessCode, setAccessCode] = useState("");
  const [isAccessModal, setIAccessModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const [error, setError] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let accessCode = searchParams.get("accessCode");
    if (accessCode) {
      // setAccessCode(accessCode)
      veryfyAccesCode(accessCode);
    } else {
      // setAccessCode('')
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [navigate, isAuthenticated]);

  const fetchAllPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(getPatientsAction());
    } catch (error) {
      console.error("Error fetching patients:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const formulatePatientsData = useCallback(() => {
    if (patients) {
      console.log("patients", patients);
      const data = patients.map((patient) =>
        createData(
          patient.patient_id,
          patient.patient_name,
          patient.gender,
          patient.phone_number,
          patient.dob,
          patient.email
        )
      );
      setPatientData(data);
    } else {
      setPatientData(null);
    }
  }, [patients]);

  useEffect(() => {
    formulatePatientsData();
  }, [patients, formulatePatientsData]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);

      fetchAllPatients();
    }
  }, [isAuthenticated, fetchAllPatients]);

  const handlePatientClick = async (id, phone_number) => {
    await dispatch(setSelectedPatient({ id, phone_number }));
    navigate(`/records?patient_id=${id}`);
  };

  const veryfyAccesCode = async (codeFromUrl) => {
    setIsVerifying(true);
    setError("");

    try {
      const url = `${API_ENDPOINT.VERFYCONSENT}`;
      const response = await apiClient.post(url, {
        access_code: accessCode || codeFromUrl,
      });
      console.log("respons", response.status);
      if (response.status === "success") {
        if (isAuthenticated) fetchAllPatients();

        setAccessCode("");
        setIsVerifying(false);
        if (searchParams.has("accessCode")) {
          searchParams.delete("accessCode");
          setSearchParams(searchParams);
        }
        handlePatientClick(response.result.patient_id);
        setError("");
      }
    } catch (error) {
      console.log(error);
      setError(error || "somthing went wrong");
      setIsVerifying(false);
    }
  };

  const isActive = true;

  if (isActive)
    return (
      <Container>
        <HeaderWrapper>
          <HeaderLeft>
            <Header>Patients {total > 0 ? `(${total})` : ""}</Header>
          </HeaderLeft>
          <p style={{ color: "red", fontSize: "12px" }}>{error && error}</p>
          {!isVerifying ? (
            <HeaderRight>
              <SubmitButton onClick={() => setIAccessModal(true)}>
                Add Access Code
              </SubmitButton>
              <AccessCodeBtn onClick={() => setIAccessModal(true)}>
                Access Code
              </AccessCodeBtn>
            </HeaderRight>
          ) : (
            ""
          )}
        </HeaderWrapper>
        {isLoading ? (
          <ShimmerTable row={5} />
        ) : (
          <>
            {!searchedPatient && patientData?.length > 0 ? (
              <PatientContainer>
                {patientData.map((patient) => {
                  const {
                    patient_id,
                    patient_name,
                    gender,
                    phone_number,
                    dob,
                    email,
                  } = patient;
                  const showGender = gender != null;
                  const showDob = dob != null && dob !== "";
                  return (
                    <PatientCard
                      key={patient_id}
                      onClick={() =>
                        handlePatientClick(patient_id, phone_number)
                      }
                    >
                      <ProfileSection>
                        <ProfileImage
                          src={IProfileImageSmall}
                          alt="ProfileImage"
                        />
                        <div>
                          <PatientInfo>
                            {patient_name ? (
                              <Name title={patient_name}>{patient_name}</Name>
                            ) : (
                              <Name
                                title={patient_name}
                                style={{ visibility: "hidden" }}
                              >
                                Placeholder
                              </Name>
                            )}
                            {phone_number ? (
                              <PhoneNumber title={phone_number}>
                                {phone_number}
                              </PhoneNumber>
                            ) : (
                              <PhoneNumber
                                title={phone_number}
                                style={{ visibility: "hidden" }}
                              >
                                placeholder
                              </PhoneNumber>
                            )}
                          </PatientInfo>

                          {showGender || showDob ? (
                            <>
                              {showGender ? (
                                <DetailItem>
                                  <GenderImage
                                    src={IGenderIcon}
                                    alt="Gender"
                                  ></GenderImage>
                                  <GenderText>
                                    {capitalizeFirstLetter(gender)}
                                  </GenderText>
                                </DetailItem>
                              ) : (
                                <DetailItem style={{ visibility: "hidden" }}>
                                  <GenderImage
                                    src={IGenderIcon}
                                    alt="Gender"
                                  ></GenderImage>
                                  <GenderText>Placeholder</GenderText>
                                </DetailItem>
                              )}
                              {showDob ? (
                                <DetailItem>
                                  <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                  <DobText>
                                    <DobDate>
                                      {moment(dob, "DD-MM-YYYY").format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </DobDate>
                                    <DobYears>
                                      {" "}
                                      (
                                      {moment().diff(
                                        moment(dob, "DD-MM-YYYY"),
                                        "years"
                                      )}{" "}
                                      Years)
                                    </DobYears>
                                  </DobText>
                                </DetailItem>
                              ) : (
                                <DetailItem style={{ visibility: "hidden" }}>
                                  <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                  <DobText>Placeholder</DobText>
                                </DetailItem>
                              )}
                            </>
                          ) : (
                            <>
                              <DetailItem style={{ visibility: "hidden" }}>
                                <GenderImage
                                  src={IGenderIcon}
                                  alt="Gender"
                                ></GenderImage>
                                <GenderText>Placeholder</GenderText>
                              </DetailItem>
                              <DetailItem style={{ visibility: "hidden" }}>
                                <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                <DobText>Placeholder</DobText>
                              </DetailItem>
                            </>
                          )}
                          {email ? (
                            <EmailSection>
                              <EmailIcon src={IEmailIcon} alt="Email" />
                              <Email>{email}</Email>
                            </EmailSection>
                          ) : (
                            <EmailSection style={{ visibility: "hidden" }}>
                              <EmailIcon src={IEmailIcon} alt="Email" />
                              <Email>Placeholder</Email>
                            </EmailSection>
                          )}
                        </div>
                      </ProfileSection>
                    </PatientCard>
                  );
                })}
              </PatientContainer>
            ) : (
              <>
                {/* <PatientCard
                  key={"patient_id"}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        onChange={(e) => setAccessCode(e.target.value)}
                        type={"text"}
                        value={accessCode}
                        placeholder="Enter access code"
                        style={{ marginBottom: "20px" }}
                      ></TextField>

<SubmitButton
                        onClick={() => veryfyAccesCode()}
                        disabled={!accessCode || isVerifying}
                      >
                        {isVerifying ? (
                          <Loader height={30} size={30} />
                        ) : (
                          "Submit"
                        )}
                      </SubmitButton>
                      <p style={{color:'red',fontSize:'12px'}}>{error && error}</p>
                    </div>
                  </div>
                </PatientCard> */}
                <EmptyListMessage>No patients data found</EmptyListMessage>
              </>
            )}
          </>
        )}

        {isAccessModal ? (
          <AccessCodeModal
            open={isAccessModal}
            handleClose={() => setIAccessModal(!isAccessModal)}
            fetchAllPatients={fetchAllPatients}
            handlePatientClick={handlePatientClick}
          />
        ) : (
          ""
        )}

        <SnackBar />
      </Container>
    );
  return <div></div>;
};
export default PatientList;
