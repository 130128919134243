import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  AuthSlice,
  PatientProfileSlice,
  PatientSlice,
  RecordTypeSlice,
  RecordsSlice,
  StatusSlice,
  ThumbnailSlice,
} from "./slice";

const rootReducer = combineReducers({
  auth: AuthSlice,
  patient: PatientSlice,
  patientProfile: PatientProfileSlice,
  recordType: RecordTypeSlice,
  record: RecordsSlice,
  status: StatusSlice,
  thumbnail: ThumbnailSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["status"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "generate-doc/fulfilled",
        ],
        ignoredPaths: ["generateDoc.document"],
      },
    }),
});

const persistor = persistStore(store);

export { persistor, store };
